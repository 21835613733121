/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:91d33785-edf1-487b-a291-f0530d92dfdb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_varz4iKLz",
    "aws_user_pools_web_client_id": "50ukjik044b1bokmmdofv3r33f",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dashboardTable-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "dashboardApi",
            "endpoint": "https://c6erc3pug4.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
